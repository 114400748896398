import "./Feedback.scss";

import { API_END_POINTS, feedbackChipLabel } from "../../Constants/Constants";
import { useState } from 'react';
import { AppaxiosInstance } from "../../Utilities/Interceptor/Interceptor";
import { nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import LikeShareOptions from "../../Elements/LikeShareOptions/LikeShareOptions";

const Feedback = (feedbackProps: any) => {
    const [selectedChipId, setSelectedChipId] = useState(null)
    const [comment, setComment] = useState('');

    const hadleChipClick = (chipId: any) => {
        setSelectedChipId((prevSelectedChipId: any) => { return prevSelectedChipId === chipId ? null : chipId });
    }
    const unlikeComment = async () => {
        let payload = {
            "feedback_comment": comment,
            "feedback_status": !feedbackProps.isUnLikeddata
        }
        AppaxiosInstance.post(`feedback_chat?answer_id=${feedbackProps.answerId}`, payload).then((response) => {
            if (response?.data.status_code === API_END_POINTS.status_code) {
                feedbackProps.setHasSubmitterFeedback(true);
                feedbackProps.handleUnlike();
            }
        })
    }
    return <>
        <section className="feedback-wrapper bg-dark-grey-medium-opacity">
            <div className="d-flex justify-content-between align-items-start gap-3">
                <h6 className="empathy-statement font-color-extra-light-grey font-exsm">We’re sorry to hear that! Please tell us more about it. (optional)</h6>
                <button type="button" className="cross bg-dark-grey" onClick={feedbackProps.handleUnlike}></button>
            </div>
            <div className="d-flex flex-wrap align-content-start gap-2">
                {feedbackChipLabel.map((feedbacklabelElement: any) => {
                    return <button key={feedbacklabelElement.id} className={`feedback-chips w-auto font-thin bg-mild-grey-hover ${selectedChipId && selectedChipId === feedbacklabelElement.id ? 'bg-mild-grey font-color-white' : 'bg-dark-grey font-color-extra-light-grey'}`} onClick={() => { hadleChipClick(feedbacklabelElement.id); setComment(feedbacklabelElement.label) }}>{feedbacklabelElement.label}</button>
                })}
            </div>
            <textarea className="feedback-description-field bg-dark-grey font-color-extra-light-grey" rows={3} onChange={(event: any) => setComment(event.target.value)}></textarea>
            <button type="submit" className="feedback-submit-button font-color-extra-light-grey bg-dark-grey font-thin" onClick={unlikeComment}>Submit</button>
        </section></>
}

export default Feedback;