import PinListIcon from "../assets/ChatHistory/pin-list.svg";
import ExternalLinkIcon from "../assets/ChatHistory/external-link.svg";
import BulkListIcon from "../assets/ChatHistory/add-folder.svg";
import React from "react";

window.React = React;

/**Routes */
export const ROUTES: any = {
  DOMAIN_FIELDS: "/get-started",
  DATA_PROGRESS: "/progress",
  CHAT_CONTENT: "/generate-content/:id",
  CHAT_CONTENT_INITIAL: "/generate-content",
  DATA_PROGRESS_STATUS: "/progressstatus/:id"
};

/**API end points */
export const API_END_POINTS: any = {
  status_code: 200
};

/**Authentication pages constants*/
export const AUTHENTICATION: any = {
  SHARED: {
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "refreshToken",
    PROFILE_PICTURE: "profilePicture",
    NAME: "name",
    EMAIL: "email",
    ACCOUNT_TYPE: "accountType",
    STATUS: "status",
    TRUE: true,

  }
};
export const searchHistoryData: any = [{ 'title': 'www.heinekin.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.swiggy.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.amazon.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.heinekin.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.swiggy.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.amazon.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.heinekin.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.swiggy.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.amazon.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.heinekin.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.swiggy.com', 'desc': 'Try it out our products llm Ask the question from the website' }, { 'title': 'www.amazon.com', 'desc': 'Try it out our products llm Ask the question from the website' }];

export const backgroundimage: any = [
  '/assets/DomainProgress/domain-bg.jpg'
];


export const progressData = [
  { label: 'Collecting Links', status: 'completed' },
  { label: 'Crawling text from the links', status: 'in progress' },
  { label: 'Indexing the crawled text', status: 'pending' },
  { label: 'embedding model', status: 'pending' },
  { label: 'Finishing it up!', status: 'pending' },
]


export const chatHistoryData = [
  { label: 'Today', title: ['Hi, Tell me a story'] },
  { label: 'Yesterday', title: ['Hi, Tell me a story', 'Hi, Tell me a story', 'Hi, Tell me a story', 'Hi, Tell me a story', 'Hi, Tell me a story'] },
]


export const tryLinks = ['Apple', 'Crypto', 'NFT', 'AI', 'Figma', 'Foundation', 'Web3 platform'];

export const feedbackChipLabel = [{ label: 'Offensive / Unsafe', id: 1 }, { label: 'Not factually correct', id: 2 }, { label: 'Other', id: 3 }];

export const irrelevantMessage =
  "This message doesn’t seem related to this section. Please check the topic or let me know how I can help!";
export const selectOrgCategoryMessage =
  "Select a category to know more about the selected organization or add this to your list.";

export const orgnizationQuickButtons: any = [
  { query: " - this is the selected organization - know about the organization", uiQuery: "Organization", type: 'button' },
  { query: " - this is the selected organization - know about the functional", uiQuery: "Functional", type: 'button' },
  { query: " - this is the selected organization - know about the Operations", uiQuery: "Operations", type: 'button' },
  { query: " - this is the selected organization - know about the Finance", uiQuery: "Finance", type: 'button' },
  { query: " - add this organization to my list", uiQuery: "Add to my list", icon: <img className="icon-img" src={PinListIcon} alt='icon-pin-list' />, type: 'button' },
  { query: "", uiQuery: "Link to redirect", icon: <img className="icon-img" src={ExternalLinkIcon} alt='icon-external-link' style={{ width: '14px' }} />, type: 'link' },
  { query: "bulk list - ", uiQuery: "Add to my Bulk List", icon: <img className="icon-img" src={BulkListIcon} alt='icon-bulk-link' style={{ width: '14px' }} />, type: 'bulk' },
];

export const belowListStaticMessage =
  " Here is the list of categories that I have identified can be something you are searching for. Can you please tell me which specific category I should be looking into ";

export const belowListStaticMessageOrg =
  "End of the search results. would you like to search another category?";


export const CHART_COLORS = ['#2be1dd', '#f7a35c', '#18b465']

export const CHART_REDIRECT_LINK = 'https://appaz.upahara.com/donors/Mei'
export const REDIRECT_LINK_PARAMS_MAP: any = {
  'organisation': { key: 'organisation', bucket: 'od' },
  'finance': { key: 'finance', bucket: 'fh', staticDesc: "This section provides detailed information about the organization's revenues and expenses, details the organization's assets and liabilities. By examining this section, you can gain valuable insights into the organization's financial stability and how effectively it manages its resources." },
  'operation': { key: 'operation', bucket: 'op', staticDesc: "This section provides valuable insights organization's governing body, its policies, organization's leadership, the disclosure practices of the nonprofit. Also discloses the organization's officers, directors, trustees, and key employees. It provides information about their compensation and the organization's governance practices." },
  'functional': { key: 'functional', bucket: 'fe', staticDesc: "This section provides the overview of the distribution of expenses across program services, administration, and fundraising efforts. A comparison of expenses over the year, providing a sense of the organization's financial balance and long-term sustainability. The organization's net assets or fund balances, which represent the difference between total assets and total liabilities." },
}