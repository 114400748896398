import "./Header.scss";
import meiLogoDarkTheme from "../../assets/ChatHistory/meiLogoDarkTheme.svg";
import menu from "../../assets/ChatHistory/menu.svg";
import meiLogoSmall from "../../assets/global/meiLogoSmall.svg";
import meiiAiColorLogo from "../../assets/ChatHistory/meiicolorlogo.svg";

const Header = (headerProps: any) => {

    return <>
        <section className="d-flex justify-content-between p-3 header-wrapper position-fixed top-0 bg-dark-grey d-xl-none">
            <img id="hamburger" src={menu} alt="" className="menu-icon cursor-pointer" onClick={headerProps.handleHistoryMenu} />
            <div className="d-flex gap-2">
                <img id="meilogohide" src={meiLogoSmall} alt="" className="menu-icon menu-icon-dark" />
                <img src={meiLogoDarkTheme} alt="" className="menu-icon menu-icon-dark" />
                <img src={meiiAiColorLogo} alt="" className="menu-icon color-icon" />

            </div>
            <div></div>
            {/* <img src={menu} alt="" className="menu-icon cursor-pointer" onClick={headerProps.handleCapabilitiesMenu} /> */}
        </section>
    </>
}

export default Header;