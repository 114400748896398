import { useEffect, useState } from "react";
import CapabilitiesTryLinks from "../../Components/CapabilitiesTryLinks/CapabilitiesTryLinks";
import ChatHistory from "../../Components/ChatHistory/ChatHistory";
import ChatWindow from "../../Components/ChatWindow/ChatWindow";
import Header from "../../Components/Header/Header";
import SidebarComp from "../../Components/Sidebar/Sidebar";
import "./ContentGeneratorLayout.scss";

const ContentGeneratorLayout = () => {
    const [isHistorySidebarOpen, setIsHistorySidebarOpen] = useState(false)
    const [isCapabilitiesSidebarOpen, setIsCapabilitiesSidebarOpen] = useState(false)

    const handleHistoryMenu = () => {
        if (isCapabilitiesSidebarOpen) handleCapabilitiesMenu();
        setIsHistorySidebarOpen((prevState: any) => { return !prevState });
    }
    const handleCapabilitiesMenu = () => {
        if (isHistorySidebarOpen) handleHistoryMenu();
        setIsCapabilitiesSidebarOpen((prevState: any) => { return !prevState })
    }

    const handleOverlay = () => {
        setIsHistorySidebarOpen(false);
        setIsCapabilitiesSidebarOpen(false);
    }

    return <>
        <section className="bg-dark-grey chat-genertor-layout-container mx-0 row">
            <div className={`mobile-sidebar-wrapper mobile-histroy-sidebar  bg-dark-grey  ${isHistorySidebarOpen ? 'active' : ''}`}>
                <SidebarComp />
                <ChatHistory handleHistoryMenu={handleHistoryMenu} />
            </div>
            <div className="d-flex flex-column h-100 col-xl px-0">
                <Header handleHistoryMenu={handleHistoryMenu} handleCapabilitiesMenu={handleCapabilitiesMenu} />
                <ChatWindow />
            </div>
            {/* <div className={`mobile-sidebar-wrapper mobile-capabilities-sidebar bg-dark-grey ${isCapabilitiesSidebarOpen ? 'active' : ''}`}>
                <CapabilitiesTryLinks />
            </div> */}
            <div className={`overlay ${isHistorySidebarOpen || isCapabilitiesSidebarOpen ? 'active' : ''}`} onClick={handleOverlay}></div>
        </section>
    </>
}

export default ContentGeneratorLayout;