import { useNavigate, useParams } from "react-router-dom";
import "./ChatHistory.scss";
import meiLogoDarkTheme from "../../assets/ChatHistory/meiLogoDarkTheme.svg";
import { ROUTES } from "../../Constants/Constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { AppaxiosInstance } from '../../Utilities/Interceptor/Interceptor'
import Slider from "react-slick";
import moment from 'moment';
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { setChatButtonStatus, setisChatButtonEnableStatus, sethistoryUpdateStatus } from "../../Redux/Reducer/Reducer";
import { Tooltip } from 'react-tooltip'
import { nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
import CapabilitiesTryLinks from "../CapabilitiesTryLinks/CapabilitiesTryLinks";

const ChatHistory = (chatHistoryProps: any) => {
    const dispatch = useDispatch()
    let { id } = useParams();
    const keywordsRef = useRef<any>(null);
    const searchNewChatRef = useRef<any>(null);
    const keywordCardRef = useRef<any>(null);
    const navigate = useNavigate();
    const [getHistoryData, setHistoryData] = useState<any>({})
    const [getCurrentDate, setCurrentDate] = useState<any>('')
    const [getsearchTerm, setSearchTerm] = useState('');
    const [getLinkCount, setLinkCount] = useState<any>('');
    const [getintialHistoryResponse, setintialHistoryResponse] = useState<any>([])
    const [historyHeight, sethistoryHeight] = useState(0)
    const hasHistoryChangedData = useSelector((state: any) => { return state.chatButtonReducer.isChatButton })
    const hasHistoryChanged = useSelector((state: any) => { return state.historyUpdateReducer.historyUpdate })
    const isChatButtonenabled = useSelector((state: any) => { return state.chatButtonEnableReducer.isChatButtonEnable })
    const beforeChange = (prev: number, next: number) => {
        const prevSlideElement = keywordsRef.current.innerSlider.list.querySelector(`[data-index="${prev}"]`);
        const nextSlideElement = keywordsRef.current.innerSlider.list.querySelector(`[data-index="${next}"]`);
        setTimeout(() => {
            prevSlideElement.classList.remove('next-slide-anim');
            nextSlideElement.classList.add('next-slide-anim');
        }, 1);
    }

    const slickSettings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000,
        beforeChange: (prev: any, next: any) => beforeChange(prev, next)
    }
    useEffect(() => {
        if (nullUndefinedEmptyCheck(id)) {
            dispatch(setisChatButtonEnableStatus(false))
        }

    })

    useEffect(() => {
        const currentDate = new Date();
        setCurrentDate(moment(currentDate).format('YYYY-MM-DD'))
        fetchHistoryData()
        fetchfooterData()
        updateHistoryHeight();
        window.addEventListener("resize", updateHistoryHeight);
        return () => window.removeEventListener("resize", updateHistoryHeight);

    }, [hasHistoryChanged])

    useEffect(() => {
        updateHistoryHeight();
    }, [searchNewChatRef.current, keywordCardRef.current])

    const updateHistoryHeight = () => {
        if (searchNewChatRef.current && keywordCardRef.current) sethistoryHeight(searchNewChatRef.current.offsetHeight + keywordCardRef.current.offsetHeight + 78)
    }

    function fetchHistoryData() {
        AppaxiosInstance.get('history_list').then((response: any) => {
            if (response) {
                if (response.data.data.length > 0) {
                    setintialHistoryResponse(response.data.data)
                    const groupedBy = groupByDateHistoryData(response.data.data);
                    setHistoryData(groupedBy)
                } else {
                    setHistoryData({})
                }
            }
        })
    }

    function fetchfooterData() {
        AppaxiosInstance.get('link_count').then((response: any) => {
            if (response) {
                setLinkCount(response.data.data)
            } else {
                setLinkCount('')
            }

        })
    }
    function filterHistorydata(searchTerm: any) {
        setSearchTerm(searchTerm)
        const groupedByFilter = getintialHistoryResponse.filter(
            (topicNameData: any) => {
                if (!searchTerm) return true;
                return topicNameData.topic_name.toString().toLowerCase().includes(searchTerm.toLowerCase())
            }
        );
        const groupedBy = groupByDateHistoryData(groupedByFilter);
        setHistoryData(groupedBy)
    }

    const groupByDateHistoryData = (historyData: any) => {

        const currentDate = new Date().setHours(0, 0, 0, 0) // Reset time to midnight

        const groupedData = historyData.reduce((acc: any, item: any) => {
            const itemDate = new Date(item.date).setHours(0, 0, 0, 0)
            const groupKey = itemDate === currentDate ? 'Today' : 'Past';
            acc[groupKey] = (acc[groupKey] || []).concat(item);
            return acc;
        }, {});
        return groupedData
    };
    const historyTrigger = (chatId: any) => {

        navigate(`${ROUTES.CHAT_CONTENT_INITIAL}/${chatId}`)
        dispatch(setisChatButtonEnableStatus(false))
    }

    function newChatTrigger() {
        chatHistoryProps.handleHistoryMenu();
        dispatch(setChatButtonStatus(hasHistoryChangedData))
        dispatch(setisChatButtonEnableStatus(true))
        dispatch(sethistoryUpdateStatus(true))
        navigate(`${ROUTES.CHAT_CONTENT_INITIAL}`)
    }

    // const secretPass = "XkhZG4fW2t2W";
    // const encryptData = (text: any) => {
    //     return CryptoJS.AES.encrypt(
    //         JSON.stringify(text),
    //         secretPass
    //     ).toString();

    // };
    return <>
        <section className="chat-history-wrapper d-flex flex-column justify-content-between">
            <div>
                <div ref={searchNewChatRef}>
                    <img src={meiLogoDarkTheme} alt="" className="mei-logo" />
                    <div className="chat-search-field-wrapper w-100">
                        <input type="search" name="" id="" placeholder="Search" className="chat-search-field font-color-extra-light-grey bg-dark-grey w-100" value={getsearchTerm}
                            onChange={(e) => filterHistorydata(e.target.value)} />
                        <button className="search-button-wrapper bg-light-grey"></button>
                    </div>
                    <button className={` ${!isChatButtonenabled ? 'new-chat font-color-white bg-light-grey w-100 text-center continue-blur' : 'new-chat font-color-white bg-light-grey w-100 text-center'} '`} disabled={!isChatButtonenabled} onClick={newChatTrigger}>New Chat</button>
                </div>
                <div>
                    <div className="chat-history-data-container" style={{ height: `calc(100vh - ${historyHeight}px` }}>
                        {/* {Object.keys(getHistoryData).map((date: any) => (
                            <ul key={date} className="font-color-extra-light-grey ">
                                <div className="font-thin chat-history-date">{moment(date).isSame(moment(), 'day') ? 'Today' : 'Past'}</div>
                                {getHistoryData[date].map((chatHistoryDataElements: any, index: any) => (
                                    <li onClick={() => { navigate(`${ROUTES.CHAT_CONTENT_INITIAL}/${chatHistoryDataElements.id}`) }} key={index} className={`bg-mild-grey-hover ${chatHistoryDataElements.id.toString() === id ? "bg-mild-grey" : ""}`}>{chatHistoryDataElements.topic_name}</li>
                                ))}
                            </ul>
                        ))} */}

                        {Object.entries(getHistoryData).map(([groupKey, items]) => (
                            <div key={groupKey} className="font-color-extra-light-grey ">
                                <div className="font-thin chat-history-date">{groupKey}</div>
                                <ul>
                                    {getHistoryData[groupKey].map((chatHistoryDataElements: any, index: any) => (
                                        <a data-tooltip-id={index + 1} key={index} className="chat-history-anchor">
                                            <li onClick={() => { chatHistoryProps.handleHistoryMenu(); historyTrigger(chatHistoryDataElements.id) }} className={`bg-mild-grey-hover ${chatHistoryDataElements.id.toString() === id ? "bg-mild-grey" : ""}`}><p>{chatHistoryDataElements.topic_name}</p>
                                            </li>
                                            <Tooltip
                                                id={index + 1}
                                                place="right"
                                                content={chatHistoryDataElements.topic_name}
                                            />
                                        </a>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div >
                </div>
            </div >
            {/* <Slider ref={keywordsRef} {...slickSettings}> */}
            {/* <div> */}
            <div ref={keywordCardRef} className="keyword-mentions-wrapper bg-light-grey">
                <h2 className="keyword-count font-color-white font-lg">{getLinkCount} <span className="mention-label font-color-white font-md">Links</span></h2>

                <p className="mentions-description font-color-extra-light-grey font-exsm">Fetched from : {sessionStorage.getItem('weblink_domain')}</p>
                <p className="parent-org font-color-extra-light-grey">Brought to life by <a href="https://www.w2xlabs.com/" target="_blank" rel="noopener" className="font-color-extra-light-grey">W2X LABS</a></p>

            </div>
            {/* </div> */}
            {/* <div>
                    <div className="keyword-mentions-wrapper bg-light-grey">
                        <h2 className="keyword-count font-color-white font-lg">1340</h2>
                        <p className="mention-label font-color-white font-exsm">Mentions of keyword</p>
                        <p className="mentions-description font-color-extra-light-grey font-exsm">Design services are focused on transforming the essence of your brand into a visually. Lorem Ipsum contour Domer.</p>
                    </div>
                </div>
                <div>
                    <div className="keyword-mentions-wrapper bg-light-grey">
                        <h2 className="keyword-count font-color-white font-lg">1360</h2>
                        <p className="mention-label font-color-white font-exsm">Mentions of keyword</p>
                        <p className="mentions-description font-color-extra-light-grey font-exsm">Design services are focused on transforming the essence of your brand into a visually. Lorem Ipsum contour Domer.</p>
                    </div>
                </div> */}
            {/* </Slider> */}
            <div className="d-xl-none d-block">
                <CapabilitiesTryLinks />
            </div>
        </section >
    </>
}

export default ChatHistory;

function useResizeDetector(arg0: { onResize: any; }): { ref: any; } {
    throw new Error("Function not implemented.");
}
