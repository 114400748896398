import "./ChatWindow.scss";
import logo from "../../assets/global/meiLogoSmall.svg";
import dualStar from "../../assets/ChatHistory/dualStar.svg";
import meiLogoSmallColored from "../../assets/ChatHistory/meiLogoSmallColored.svg";
import userAvatar from "../../assets/ChatHistory/userAvatar.png";
import { useEffect, useMemo, useRef, useState } from "react";
import { AppaxiosInstance } from '../../Utilities/Interceptor/Interceptor'
import { API_END_POINTS } from "../../Constants/Constants";
import LikeShareOptions from "../../Elements/LikeShareOptions/LikeShareOptions";
import { useParams } from "react-router-dom";
import { checkHistoryFlow, nullUndefinedEmptyCheck, nullUndefinedEmptySpaceCheck, prepareQueryLink, pythonStringToJSON } from "../../Utilities/Helpers/Helper";
import { setChatButtonStatus, sethistoryUpdateStatus, setisChatButtonEnableStatus } from "../../Redux/Reducer/Reducer";
//import { socket } from '../../socket'
import { io } from "socket.io-client";
import Feedback from "../Feedback/Feedback";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import ChartMessage from "../ChartMessage/ChartMessage";
import {
    CHART_COLORS,
    CHART_REDIRECT_LINK,
    orgnizationQuickButtons,
    REDIRECT_LINK_PARAMS_MAP,
    selectOrgCategoryMessage,
    belowListStaticMessage,
    belowListStaticMessageOrg

} from "../../Constants/Constants";
import { Bar, BarChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const ChatWindow = () => {
    let { id } = useParams();
    const dispatch = useDispatch()
    const suggestionsCardsRef = useRef<any>(null)
    const discoverRef = useRef<any>(null)
    const [isChatStarted, setIsChatStarted] = useState(false);
    const [generateInitialContent, setGenerateInitialContent] = useState(false);
    const [getPredefinedQuestionData, setPredefinedQuestionData] = useState([]);
    const [getQueryValue, setQueryValue] = useState('');
    const [sugestionsCardHeight, setSuggestionsCardHeight] = useState(null);
    const [discoverHeight, setDiscoverHeight] = useState(null);
    const [clickedStrings, setClickedStrings] = useState<any>([]);
    const [getQueryValueForClick, setQueryValueForClick] = useState('');
    const [loadingStates, setLoadingStates] = useState<any>(false);
    const [lastClickedEinnum, setLastClickedEinnum] = useState(null);
    const refLabeLForInput = useRef()
    const [getHistoryData, setHistoryData] = useState({});
    const [resLoading, setResLoading] = useState(false);
    const [lastSelectedSeeMore, setLastSelectedSeeMore] = useState(null);
    useEffect(() => {
        setSuggestionsCardHeight(suggestionsCardsRef.current?.scrollHeight);
        setDiscoverHeight(discoverRef.current?.scrollHeight);
    }, [getPredefinedQuestionData, suggestionsCardsRef.current, discoverRef.current])

    const [getQueryTopicId, setQueryTopicId] = useState('')
    const [getTellmeLinkShow, setTellmeLinkShow] = useState(false)
    const [questionAnswer, setQuestionAnswer] = useState<any>([]);
    const [renderQuestAnswer, setrenderQuestAnswer] = useState<any>([]);
    const [isReloadActive, setIsReloadActive] = useState(false);
    const [getPrevQuestId, setPrevQuestId] = useState('')
    const inputRef = useRef<any>(null);
    const chatContainerRef = useRef<any>(null);
    const latestAnswerRef = useRef<any>(null);
    const hasHistoryChangedData = useSelector((state: any) => { return state.chatButtonReducer.isChatButton })
    const socket = 'https://sse-stg.meii.ai';
    let eventSource: any
    // const socket = io('wss://meii-redis-socket-fzrkutbmyq-uc.a.run.app', {
    //     transports: ['websocket'], withCredentials: true,
    //     // reconnection: true, reconnectionAttempts: 20,
    //     // reconnectionDelay: 5000
    // })

    useEffect(() => {
        if (isChatStarted) {
            setTimeout(() => {
                setGenerateInitialContent(true);
            }, 1000);
            setrenderQuestAnswer([])
            setQueryTopicId('')
        }
    }, [isChatStarted])


    useEffect(() => {
        fetchPreDefinedQuestions()
    }, [])


    useEffect(() => {
        handleBackButton()
        dispatch(setChatButtonStatus(false))
    }, [hasHistoryChangedData])

    useEffect(() => {
        if (nullUndefinedEmptyCheck(id)) {
            fetchQuestionAnswerData(id);
            setIsChatStarted(true);
        } else {
            setIsChatStarted(false);

        }
    }, [id])

    // useEffect(() => {
    //     return () => {
    //         if (socket) {
    //             socket.disconnect();
    //             if(getQueryTopicId) {
    //                 socket.emit('leave', getQueryTopicId);
    //             }
    //         }
    //     };
    // }, []);

    const queryApiTrigger = (queryValues: any) => {
        if (resLoading) return;

        fetchApiTrigger(queryValues, 1);
        inputRef.current.blur();
    };

    const onMore = (type: any, { query, uiQuery }: any) => {
        let updatedQuery = query;
        if (type === "seemore") {
            // Whenever the see more is clicked set it in a seperate state so that the upcomming messages
            // will be related to that
            setLastSelectedSeeMore(uiQuery);
        } else if (type === "suggestion") {
            // Whenever the suggestion below the organization details is clicked
            // attach the last selected orgatization detail also
            updatedQuery = `${lastSelectedSeeMore}${query}`;
        }
        // else if (lastSelectedSeeMore){
        //   // Whenever the last selected see more presents attach it will the query
        //   updatedQuery = `${lastSelectedSeeMore}-know about the ${query}`
        // }

        queryApiTrigger({ query: updatedQuery, uiQuery: uiQuery });
    };


    useEffect(() => {
        if (getQueryTopicId) {
            eventSource = new EventSource(`${socket}/subscribe/${getQueryTopicId}`);

            eventSource.onopen = () => {
            };
            eventSource.onmessage = (event: any) => {
                getUpdateSocket(JSON.parse(event.data))
            };

            eventSource.onerror = (error: any) => {
                setLoadingStates(false)
            };
            return () => {
                eventSource.close();
                setLoadingStates(false)
            };


            // socket.on('connect', () => {
            //     socket.emit('join', getQueryTopicId);
            // });

            // socket.on('reconnect', () => {
            //     socket.emit('updateSocketId', getQueryTopicId);
            //   });

        }
    }, [getQueryTopicId])



    function getUpdateSocket(socketData: any) {
        setLoadingStates(false)
        setIsReloadActive(false)
        setrenderQuestAnswer((prev: any) => [...prev, socketData.data])
        dispatch(sethistoryUpdateStatus(false))

        dispatch(setisChatButtonEnableStatus(false))
    }

    useEffect(() => {
        // Scroll down to the bottom of the chat container
        chatContainerRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [renderQuestAnswer]);

    const fetchPreDefinedQuestions = async () => {
        await AppaxiosInstance.get('prompt_question').then((response: any) => {
            setPredefinedQuestionData(response.data.data)
        })
    }
    const handleInputChange = (event: any) => {
        const newInputString = event.target.value;
        setQueryValue(newInputString);
        setQueryValueForClick(newInputString)
    };
    const checkMoreAnswer = (lastIndexid: any) => {
        setIsReloadActive(true);
        setLoadingStates(true);
        fetchApiTrigger(getQueryValueForClick, 0);
        //  }
    };



    const QueryAPITrigger = (getQueryString: any, from: any) => {
        setQueryValueForClick(getQueryString)
        fetchApiTrigger(getQueryString, 1)
        inputRef.current.blur();
    }

    const fetchApiTrigger = async (getQueryString: any, from: any) => {
        setLoadingStates(true);
        let questionId = ''
        if (from == 0) {
            const questionData = renderQuestAnswer[renderQuestAnswer.length - 1]
            questionId = questionData.question_id
            setPrevQuestId(questionId)
        }
        let payload = {
            query: getQueryString?.query || getQueryString || "",
            /* uiQuery: getQueryString?.uiQuery || "", */
            topic_id: getQueryTopicId,
            question_id: questionId
        }

        AppaxiosInstance.post(`query?requery=${from == 0 ? true : false}`, payload).then((response) => {
            if (response?.data.status_code === API_END_POINTS.status_code) {
                setQueryValue('')
                setQueryTopicId(response?.data.data.topic)

                if (from === 1) {
                    setrenderQuestAnswer((prev: any) => [...prev, { uiQuery: getQueryString?.uiQuery || "", ...response?.data.data.query_data }])
                } else {
                    setrenderQuestAnswer((prev: any) => [...prev])

                }

            }
        })
    }
    const handleKeyPress = (e: any) => {
        if (e.code === "Enter" && nullUndefinedEmptySpaceCheck(getQueryValue) && !loadingStates) {
            const newInputString = e.target.value;
            setQueryValue(newInputString);
            setIsChatStarted(true);
            QueryAPITrigger(getQueryValue, 1)
            inputRef.current.blur();
        }
    };
    const fetchQuestionAnswerData = async (topicId: any) => {
        await AppaxiosInstance.get(`detail_topic?topic_id=${topicId}`).then((response: any) => {
            setQuestionAnswer(response.data.data);
        })
    }
    const decryptData = (text: any) => {
        const secretPass = "XkhZG4fW2t2W";
        const bytes = CryptoJS.AES.decrypt(text, secretPass);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };
    //questionAnswer
    const memoizedComponent = useMemo(() => {
        return <>
            {questionAnswer && questionAnswer.length > 0 ?
                (questionAnswer.map((quest: any, index: any) => {
                    console.log("🚀 ~ quest:", quest)
                    const isQuestion = quest?.question ? true : false;
                    const isAnswer = quest?.answers?.length > 0;

                    const questionMessage = isQuestion && quest?.question;
                    const isHtmlMessage = quest?.answers[0]?.is_html;
                    let answerMessage: any = "";

                    if (isAnswer && quest?.answers[0]?.answer && !isHtmlMessage) {
                        // debugger
                        // As the data as json string is not clean, clean using the regex
                        try {
                            const cleanedMessage = pythonStringToJSON(quest?.answers[0]?.answer);
                            answerMessage = JSON.parse(cleanedMessage);

                        } catch (error) {
                            console.error("Error while parsing the json string", error);
                            answerMessage = quest?.answers[0]?.answer;
                        }
                    } else if (quest?.answers[0]?.is_html) {
                        // When the type is html use the direct message
                        answerMessage = quest?.answers[0]?.answer;
                    } else {
                        answerMessage = quest?.answers[0]?.answer;
                    }

                    /* const receiverClassName = `receiver-box ${viewSize ? `view-size-${viewSize}` : ""
                        }`; */

                    const DataFormater = (value: any) => {
                        if (value >= 1_000_000) {
                            return `$${(value / 1_000_000).toFixed(1)}M`; // Millions
                        } else if (value >= 1_000) {
                            return `$${(value / 1_000).toFixed(1)}K`; // Thousands
                        }
                        return `$${value.toLocaleString()}`; // Less than 1,000
                    };
                    const tooltipFormatter = (value: any, name: any, props: any) => {
                        return [`${camelCaseFormatter(name)}: $${value.toLocaleString()}`];
                    };
                    const camelCaseFormatter = (key: any) => {
                        return key.charAt(0).toUpperCase() + key.slice(1);
                    };
                    return (
                        <div key={index}>
                            {isQuestion &&
                                (
                                    <>
                                        <div className="user-query-wrapper d-flex">
                                            <img src={userAvatar} alt="" className="user-avatar" />
                                            <p className="user-query font-color-extra-light-grey bg-dark-grey font-exsm">
                                                {(quest?.uiQuery?.includes('~~!') ? quest?.uiQuery?.split('~~!')[0] : quest?.uiQuery) || (questionMessage?.includes('~~!') ? questionMessage?.split('~~!')[0] : questionMessage)}
                                            </p>
                                        </div>
                                        <div ref={chatContainerRef}></div>
                                    </>
                                )}
                            {isAnswer && (
                                <>
                                    <div ref={chatContainerRef}></div>
                                    <div className="d-flex user-answer-wrapper" ref={(questionAnswer?.length - 1 === index) ? latestAnswerRef : null}>
                                        <img src={meiLogoSmallColored} alt="" className="user-avatar" />
                                        {/* Non list message */}
                                        <div className="d-flex flex-column gap-2">
                                            {!answerMessage?.is_list && answerMessage.type !== 'chart' && answerMessage.type !== "chart_list" && (
                                                <>
                                                    {/* All the non list message will fall into this block */}
                                                    {!isHtmlMessage && (
                                                        <p className="font-color-extra-light-grey font-exsm whitespace meii-text-message" dangerouslySetInnerHTML={{
                                                            __html: answerMessage?.data || answerMessage,
                                                        }} />
                                                    )}

                                                    {/* if is_html is true, it will fall into this block */}
                                                    {typeof answerMessage === "string" && isHtmlMessage && (<span
                                                        className="html-msg-container"
                                                        dangerouslySetInnerHTML={{
                                                            __html: answerMessage,
                                                        }}
                                                    />)}
                                                </>
                                            )}
                                            {/* List message block start */}
                                            {answerMessage?.is_list && !["category"].includes(answerMessage.type) && answerMessage?.data?.length > 0 && (
                                                <div className="d-flex flex-column gap-2">
                                                    <div className="chat-list-box">
                                                        {answerMessage?.data?.length > 0 ? (
                                                            answerMessage?.data?.map((chatDataItem: any, index: any) => {
                                                                const listLabel = chatDataItem.includes('~~!') ? chatDataItem.split('~~!')[0] : chatDataItem;
                                                                const einnum = chatDataItem.includes('~~!') ? (chatDataItem.split('~~!')[1] || '').trim() : null;
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="chat-list-container"
                                                                    >
                                                                        <p
                                                                            className="weight-medium chat-list-label font-exsm"
                                                                            title={listLabel}
                                                                        >
                                                                            {listLabel}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                    <div className='receiver-box'>
                                                        <div className="chat-list-suggestion-box">
                                                            {answerMessage?.type === "org" && !answerMessage?.meta?.seemore && (
                                                                <div className="chat-list-box">
                                                                    <p className="chat-answer">
                                                                        {belowListStaticMessage}
                                                                    </p>
                                                                </div>
                                                            )}

                                                            {answerMessage?.type === "org" && answerMessage?.meta?.seemore && answerMessage?.meta?.showmore == '' && (
                                                                <div className="chat-list-box">
                                                                    <p className="chat-answer">
                                                                        {belowListStaticMessageOrg}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {answerMessage?.is_list && answerMessage?.type === "category" && answerMessage?.data.length > 0 && (
                                                <>
                                                    <div className="chat-list-box">
                                                        {answerMessage?.data?.length > 0 ? (
                                                            answerMessage?.data?.map((item: any, index: any) => {
                                                                const { category, sub_category } = item;
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="chat-list-container"
                                                                    >
                                                                        <p
                                                                            className="weight-medium chat-list-label"
                                                                            title={category}
                                                                        >
                                                                            {category}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {!answerMessage?.is_list &&
                                                answerMessage?.type === "chart" && (
                                                    <>
                                                        {answerMessage.data.map((chartElements: any, index: any) => (
                                                            <div className="chat-list-box" key={index}>
                                                                <h6 className="chart-title">{chartElements.title}</h6>
                                                                <div className="chart-wrapper-box" key={index}>
                                                                    <ResponsiveContainer width={"100%"} height={"100%"}>
                                                                        <LineChart data={chartElements.data} width={window.innerWidth} height={window.innerHeight}>
                                                                            <XAxis dataKey="year" padding={{ left: 30, right: 30 }} />
                                                                            <YAxis tickFormatter={DataFormater} />
                                                                            <Tooltip formatter={tooltipFormatter} contentStyle={{ backgroundColor: '#000', border: 0, padding: '4px 8px' }} labelStyle={{ display: 'none' }} itemStyle={{ color: `${CHART_COLORS[index]}` }} />
                                                                            <Legend formatter={(value: any) => camelCaseFormatter(value)} />
                                                                            {Object.keys(chartElements.data[0]).slice(1,).map((chartLine, index) => (
                                                                                <Line
                                                                                    type="monotone"
                                                                                    dataKey={chartLine}
                                                                                    stroke={CHART_COLORS[index]}
                                                                                    dot={{ stroke: CHART_COLORS[index], strokeWidth: 1 }}
                                                                                    activeDot={{ stroke: CHART_COLORS[index], strokeWidth: 2 }}
                                                                                    strokeWidth={3}
                                                                                    animationDuration={1600}
                                                                                    animationBegin={1}
                                                                                    animationEasing="ease-in-out"
                                                                                // label={<CustomizedLabel />}
                                                                                />
                                                                            ))
                                                                            }

                                                                        </LineChart>
                                                                    </ResponsiveContainer>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )
                                            }
                                            {answerMessage?.type === "chart_list" && (
                                                <>
                                                    {answerMessage.data.map((chartElements: any, index: any) => (
                                                        <>
                                                            {chartElements.is_chart_list ? (
                                                                <>
                                                                    {chartElements?.data?.length > 0 ?
                                                                        <div className="chat-list-box">
                                                                            <h6 className="chart-title">{chartElements.title}</h6>
                                                                            {chartElements?.data?.length > 0 && (
                                                                                chartElements?.data?.map((chatDataItem: any, index: any) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={index}
                                                                                            className="chat-list-container"
                                                                                        >
                                                                                            <p
                                                                                                className="weight-medium chat-list-label"
                                                                                                title={chatDataItem}
                                                                                            >
                                                                                                {chatDataItem}
                                                                                            </p>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                            )}
                                                                        </div> : null}</>
                                                            ) : (
                                                                <div className="chat-list-box" key={index}>
                                                                    <h6 className="chart-title">{chartElements.title}</h6 >
                                                                    <div className="chart-wrapper-box">
                                                                        <ResponsiveContainer width={"100%"} height={"100%"}>
                                                                            <BarChart data={chartElements.data} width={window.innerWidth} height={window.innerHeight} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                                                                                <XAxis dataKey="year" padding={{ left: 30, right: 30 }} />
                                                                                <YAxis tickFormatter={DataFormater} />
                                                                                <Tooltip cursor={false} formatter={tooltipFormatter} contentStyle={{ backgroundColor: '#000', border: 0, padding: '4px 8px' }} labelStyle={{ display: 'none' }} itemStyle={{ color: `${CHART_COLORS[index]}` }} />
                                                                                <Legend formatter={(value) => camelCaseFormatter(value)} />
                                                                                {Object.keys(chartElements.data[0]).slice(1,).map((chartLine, index) => (
                                                                                    <Bar
                                                                                        dataKey={chartLine}
                                                                                        animationDuration={1600}
                                                                                        animationBegin={1}
                                                                                        animationEasing="ease-in-out"
                                                                                        barSize={26}
                                                                                        fill={CHART_COLORS[index]}
                                                                                    />
                                                                                ))}
                                                                            </BarChart>
                                                                        </ResponsiveContainer>
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                            {['chart', 'chart_list'].includes(answerMessage?.type) && (
                                                <ChartMessage answer={answerMessage} />
                                            )}
                                            {/* For Html message, type organization details. Suggestion buttons with some quick message */}
                                            {isAnswer && isHtmlMessage && (
                                                <>
                                                    <div className="chat-list-box">
                                                        <p className="weight-medium chat-answer font-color-extra-light-grey font-exsm">
                                                            {selectOrgCategoryMessage}
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>)
                })) : null}
        </>
    }, [questionAnswer]);

    const socketRenderComponent = useMemo(() => {
        return <>
            {renderQuestAnswer && renderQuestAnswer.length > 0 ?
                (renderQuestAnswer.map((quest: any, index: any) => {
                    const isQuestion = quest?.is_question;
                    const isAnswer = quest?.is_answer;

                    const questionMessage = isQuestion && quest?.message;
                    const isHtmlMessage = quest?.is_html;
                    let answerMessage: any = "";

                    if (isAnswer && quest?.message && !isHtmlMessage) {
                        // debugger
                        // As the data as json string is not clean, clean using the regex
                        try {
                            const cleanedMessage = pythonStringToJSON(quest?.message);
                            answerMessage = JSON.parse(cleanedMessage);

                        } catch (error) {
                            console.error("Error while parsing the json string", error);
                            answerMessage = quest?.message;
                        }
                    } else if (quest?.is_html) {
                        // When the type is html use the direct message
                        answerMessage = quest?.message;
                    } else {
                        answerMessage = quest?.message;
                    }

                    /* const receiverClassName = `receiver-box ${viewSize ? `view-size-${viewSize}` : ""
                        }`; */

                    const DataFormater = (value: any) => {
                        if (value >= 1_000_000) {
                            return `$${(value / 1_000_000).toFixed(1)}M`; // Millions
                        } else if (value >= 1_000) {
                            return `$${(value / 1_000).toFixed(1)}K`; // Thousands
                        }
                        return `$${value.toLocaleString()}`; // Less than 1,000
                    };
                    const tooltipFormatter = (value: any, name: any, props: any) => {
                        return [`${camelCaseFormatter(name)}: $${value.toLocaleString()}`];
                    };
                    const camelCaseFormatter = (key: any) => {
                        return key.charAt(0).toUpperCase() + key.slice(1);
                    };

                    return (
                        <>
                            <div key={index}>
                                {isQuestion &&
                                    (
                                        <>
                                            <div className="user-query-wrapper d-flex">
                                                <img src={userAvatar} alt="" className="user-avatar" />
                                                <p className="user-query font-color-extra-light-grey bg-dark-grey font-exsm">
                                                    {(quest?.uiQuery?.includes('~~!') ? quest?.uiQuery?.split('~~!')[0] : quest?.uiQuery) || (questionMessage?.includes('~~!') ? questionMessage?.split('~~!')[0] : questionMessage)}
                                                </p>
                                            </div>
                                            <div ref={chatContainerRef}></div>
                                        </>
                                    )}
                                {/* Answer block */}
                                {isAnswer &&
                                    (<>
                                        <div ref={chatContainerRef}></div>
                                        <div className="d-flex user-answer-wrapper" ref={(renderQuestAnswer?.length - 1 === index) ? latestAnswerRef : null}>
                                            <img src={meiLogoSmallColored} alt="" className="user-avatar" />
                                            {/* Non list message */}
                                            <div className="d-flex flex-column gap-2">
                                                {!answerMessage?.is_list && answerMessage.type !== 'chart' && answerMessage.type !== "chart_list" && (
                                                    <>
                                                        {/* All the non list message will fall into this block */}
                                                        {!isHtmlMessage && (
                                                            <p className="font-color-extra-light-grey font-exsm whitespace meii-text-message" dangerouslySetInnerHTML={{
                                                                __html: answerMessage?.data || answerMessage,
                                                            }} />
                                                        )}

                                                        {/* if is_html is true, it will fall into this block */}
                                                        {typeof answerMessage === "string" && isHtmlMessage && (<span
                                                            className="html-msg-container"
                                                            dangerouslySetInnerHTML={{
                                                                __html: answerMessage,
                                                            }}
                                                        />)}
                                                    </>
                                                )}
                                                {/* List message block start */}
                                                {answerMessage?.is_list && !["category"].includes(answerMessage.type) && answerMessage?.data?.length > 0 && (
                                                    <div className="d-flex flex-column gap-2">
                                                        <div className="chat-list-box">
                                                            {answerMessage?.data?.length > 0 ? (
                                                                answerMessage?.data?.map((chatDataItem: any, index: any) => {
                                                                    const listLabel = chatDataItem.includes('~~!') ? chatDataItem.split('~~!')[0] : chatDataItem;
                                                                    const einnum = chatDataItem.includes('~~!') ? (chatDataItem.split('~~!')[1] || '').trim() : null;
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="chat-list-container"
                                                                        >
                                                                            <p
                                                                                className="weight-medium chat-list-label font-exsm"
                                                                                title={listLabel}
                                                                            >
                                                                                {listLabel}
                                                                            </p>
                                                                            {answerMessage?.meta?.seemore && (
                                                                                <button
                                                                                    className="weight-regular font-exsm"
                                                                                    disabled={loadingStates}
                                                                                    onClick={() => {
                                                                                        setLastClickedEinnum(einnum)
                                                                                        onMore("seemore", {
                                                                                            query: `${chatDataItem} - this is the selected organization - know about the organization`,
                                                                                            uiQuery: chatDataItem,
                                                                                        });
                                                                                    }
                                                                                    }
                                                                                >
                                                                                    see more
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div className='receiver-box'>
                                                            <div className="chat-list-suggestion-box">
                                                                {answerMessage?.type === "org" && !answerMessage?.meta?.seemore && (
                                                                    <div className="chat-list-box">
                                                                        <p className="chat-answer">
                                                                            {belowListStaticMessage}
                                                                        </p>
                                                                    </div>
                                                                )}

                                                                {answerMessage?.type === "org" && answerMessage?.meta?.seemore && answerMessage?.meta?.showmore == '' && (
                                                                    <div className="chat-list-box">
                                                                        <p className="chat-answer">
                                                                            {belowListStaticMessageOrg}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                                <div className="suggestions-chip-wrapper-box">
                                                                    {answerMessage?.meta?.showmore && (
                                                                        <div className="suggestion-chip-container">
                                                                            <button
                                                                                className="suggestion-chips font-exsm"
                                                                                disabled={loadingStates}
                                                                                onClick={() =>
                                                                                    onMore("showmore", {
                                                                                        query: answerMessage.meta.showmore,
                                                                                        uiQuery: "Show more",
                                                                                    })
                                                                                }
                                                                            >
                                                                                Show more
                                                                            </button>
                                                                        </div>
                                                                    )}

                                                                    {answerMessage?.type === "org" && answerMessage?.meta?.seemore && answerMessage?.data?.length > 0 ?
                                                                        <div className="org-quick-btns">
                                                                            {orgnizationQuickButtons.map((button: any) => {
                                                                                if (button.type === 'bulk') {
                                                                                    return (
                                                                                        <button
                                                                                            className="suggestion-chips font-exsm"
                                                                                            key={button.query}
                                                                                            disabled={loadingStates}
                                                                                            onClick={() =>
                                                                                                onMore("", {
                                                                                                    query: `${button.query}${JSON.stringify(answerMessage?.data)}`,
                                                                                                    uiQuery: button.uiQuery,
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            <div className="icon-button-wrapper">
                                                                                                {button.icon}
                                                                                                {button.uiQuery}
                                                                                            </div>
                                                                                        </button>

                                                                                    )
                                                                                }
                                                                            })}
                                                                        </div> : null
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {answerMessage?.is_list && answerMessage?.type === "category" && answerMessage?.data.length > 0 && (
                                                    <>
                                                        <div className="chat-list-box">
                                                            {answerMessage?.data?.length > 0 ? (
                                                                answerMessage?.data?.map((item: any, index: any) => {
                                                                    const { category, sub_category } = item;
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="chat-list-container"
                                                                        >
                                                                            <p
                                                                                className="weight-medium chat-list-label"
                                                                                title={category}
                                                                            >
                                                                                {category}
                                                                            </p>
                                                                            {answerMessage?.meta?.seemore && (
                                                                                <button
                                                                                    className="weight-regular"
                                                                                    disabled={loadingStates}
                                                                                    onClick={() =>
                                                                                        onMore("seemore", {
                                                                                            query: `${category}-this is the selected category`,
                                                                                            uiQuery: category,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    see more
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                        <div className={""}>
                                                            <div className="chat-list-suggestion-box">
                                                                {answerMessage?.meta?.showmore && (
                                                                    <div className="suggestion-chip-container">
                                                                        <button
                                                                            className="suggestion-chips"
                                                                            disabled={loadingStates}
                                                                            onClick={() =>
                                                                                onMore("showmore", {
                                                                                    query: answerMessage.meta.showmore,
                                                                                    uiQuery: "Show more",
                                                                                })
                                                                            }
                                                                        >
                                                                            Show more
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {!answerMessage?.is_list &&
                                                    answerMessage?.type === "chart" && (
                                                        <>
                                                            {answerMessage.data.map((chartElements: any, index: any) => (
                                                                <div className="chat-list-box" key={index}>
                                                                    <h6 className="chart-title">{chartElements.title}</h6>
                                                                    <div className="chart-wrapper-box" key={index}>
                                                                        <ResponsiveContainer width={"100%"} height={"100%"}>
                                                                            <LineChart data={chartElements.data} width={window.innerWidth} height={window.innerHeight}>
                                                                                <XAxis dataKey="year" padding={{ left: 30, right: 30 }} />
                                                                                <YAxis tickFormatter={DataFormater} />
                                                                                <Tooltip formatter={tooltipFormatter} contentStyle={{ backgroundColor: '#000', border: 0, padding: '4px 8px' }} labelStyle={{ display: 'none' }} itemStyle={{ color: `${CHART_COLORS[index]}` }} />
                                                                                <Legend formatter={(value: any) => camelCaseFormatter(value)} />
                                                                                {Object.keys(chartElements.data[0]).slice(1,).map((chartLine, index) => (
                                                                                    <Line
                                                                                        type="monotone"
                                                                                        dataKey={chartLine}
                                                                                        stroke={CHART_COLORS[index]}
                                                                                        dot={{ stroke: CHART_COLORS[index], strokeWidth: 1 }}
                                                                                        activeDot={{ stroke: CHART_COLORS[index], strokeWidth: 2 }}
                                                                                        strokeWidth={3}
                                                                                        animationDuration={1600}
                                                                                        animationBegin={1}
                                                                                        animationEasing="ease-in-out"
                                                                                    // label={<CustomizedLabel />}
                                                                                    />
                                                                                ))
                                                                                }

                                                                            </LineChart>
                                                                        </ResponsiveContainer>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    )
                                                }
                                                {answerMessage?.type === "chart_list" && (
                                                    <>
                                                        {answerMessage.data.map((chartElements: any, index: any) => (
                                                            <>
                                                                {chartElements.is_chart_list ? (
                                                                    <>
                                                                        {chartElements?.data?.length > 0 ?
                                                                            <div className="chat-list-box">
                                                                                <h6 className="chart-title">{chartElements.title}</h6>
                                                                                {chartElements?.data?.length > 0 && (
                                                                                    chartElements?.data?.map((chatDataItem: any, index: any) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={index}
                                                                                                className="chat-list-container"
                                                                                            >
                                                                                                <p
                                                                                                    className="weight-medium chat-list-label"
                                                                                                    title={chatDataItem}
                                                                                                >
                                                                                                    {chatDataItem}
                                                                                                </p>
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                )}
                                                                            </div> : null}</>
                                                                ) : (
                                                                    <div className="chat-list-box" key={index}>
                                                                        <h6 className="chart-title">{chartElements.title}</h6 >
                                                                        <div className="chart-wrapper-box">
                                                                            <ResponsiveContainer width={"100%"} height={"100%"}>
                                                                                <BarChart data={chartElements.data} width={window.innerWidth} height={window.innerHeight} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                                                                                    <XAxis dataKey="year" padding={{ left: 30, right: 30 }} />
                                                                                    <YAxis tickFormatter={DataFormater} />
                                                                                    <Tooltip cursor={false} formatter={tooltipFormatter} contentStyle={{ backgroundColor: '#000', border: 0, padding: '4px 8px' }} labelStyle={{ display: 'none' }} itemStyle={{ color: `${CHART_COLORS[index]}` }} />
                                                                                    <Legend formatter={(value) => camelCaseFormatter(value)} />
                                                                                    {Object.keys(chartElements.data[0]).slice(1,).map((chartLine, index) => (
                                                                                        <Bar
                                                                                            dataKey={chartLine}
                                                                                            animationDuration={1600}
                                                                                            animationBegin={1}
                                                                                            animationEasing="ease-in-out"
                                                                                            barSize={26}
                                                                                            fill={CHART_COLORS[index]}
                                                                                        />
                                                                                    ))}
                                                                                </BarChart>
                                                                            </ResponsiveContainer>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                }
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                                {['chart', 'chart_list'].includes(answerMessage?.type) && (
                                                    <ChartMessage answer={answerMessage} />
                                                )}
                                                {/* For Html message, type organization details. Suggestion buttons with some quick message */}
                                                {isAnswer && isHtmlMessage && (
                                                    <>
                                                        <div className="chat-list-box">
                                                            <p className="weight-medium chat-answer font-color-extra-light-grey font-exsm">
                                                                {selectOrgCategoryMessage}
                                                            </p>
                                                        </div>
                                                        <div className="org-quick-btns">
                                                            {orgnizationQuickButtons.map((button: any) => {
                                                                if (button.type === 'link') {
                                                                    return (
                                                                        <a
                                                                            className="suggestion-chips redirect-link"
                                                                            key={button.uiQuery}
                                                                            aria-disabled={loadingStates}
                                                                            href={prepareQueryLink(CHART_REDIRECT_LINK, { bucket: REDIRECT_LINK_PARAMS_MAP?.organisation.bucket, einnum: lastClickedEinnum })}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <div className="icon-button-wrapper">
                                                                                {button.icon}
                                                                                {button.uiQuery}
                                                                            </div>
                                                                        </a>
                                                                    )
                                                                }
                                                                if (button.type != 'link' && button.type != 'bulk') {
                                                                    return (
                                                                        <button
                                                                            className="suggestion-chips"
                                                                            key={button.query}
                                                                            disabled={loadingStates}
                                                                            onClick={() =>
                                                                                onMore("suggestion", {
                                                                                    query: button.query,
                                                                                    uiQuery: button.uiQuery,
                                                                                })
                                                                            }
                                                                        >
                                                                            <div className="icon-button-wrapper">
                                                                                {button.icon}
                                                                                {button.uiQuery}
                                                                            </div>
                                                                        </button>
                                                                    )
                                                                }
                                                            })}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="like-options-box d-flex">
                                            <div className="user-avatar" ></div>
                                            <LikeShareOptions answerId={quest.answer_id} historyflow={false} />
                                        </div>
                                    </>
                                    )
                                }
                            </div>
                        </>
                    )
                }))
                :
                null
            }
            {
                loadingStates &&
                <div className="d-flex user-answer-wrapper">
                    <img src={meiLogoSmallColored} alt="" className="user-avatar" />
                    <div className="dot-shuttle-contianer">
                        <div className="dot-shuttle"></div>
                    </div>
                </div>
            }
        </>
    }, [renderQuestAnswer]);

    const handleBackButton = () => {
        if (isChatStarted) {
            // setIsChatStarted((prevValue: any) => { return !prevValue });
            setIsChatStarted(false)
            setrenderQuestAnswer([])
            setQueryTopicId('')
            dispatch(setisChatButtonEnableStatus(true))
            dispatch(sethistoryUpdateStatus(true))
            if (eventSource) {
                eventSource.close();
            }
        }
        else {
            return
        }
    }

    return <>
        <section className={`chat-window-wrapper bg-light-grey ${isChatStarted ? 'chat-screen' : 'suggestion-screen'}`}>
            {/* {!isChatStarted &&
                <video
                    onError={(e) => {
                        console.error('Error loading video:', e);
                    }}
                    className="background-video"
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src="./chatBgVideo.mp4" type="video/mp4" />
                </video>
            } */}
            {!nullUndefinedEmptyCheck(id) ? <>
                <div className="dummy-content"></div>
                <button type="button" className="back-button-wrapper font-exsm position-relative font-color-extra-light-grey" onClick={handleBackButton}>Back</button>
                <div className="chat-window-container">
                    <div ref={suggestionsCardsRef} style={{ maxHeight: isChatStarted ? '0px' : `${sugestionsCardHeight}px` }} className="row row-cols-1 row-cols-md-2 row-cols-lg-3 suggestions-cards-wrapper">
                        {getPredefinedQuestionData && getPredefinedQuestionData.length ?
                            (<>
                                {getPredefinedQuestionData.map((index: any) =>
                                    <div key={index} className="flex-grow-1 py-1">
                                        <div className="suggestions-card-container d-flex align-items-center bg-dark-grey-less-opacity cursorshow" onClick={() => { setIsChatStarted(true); QueryAPITrigger(index, 0) }}>
                                            <img src={logo} alt="" className="suggestion-icons" />
                                            <p className="suggestion-description font-color-white font-thin">{index}</p>
                                        </div>
                                    </div>
                                )}
                            </>) : null}
                    </div>

                    <div className="d-flex"><div className="chat-input-field-wrapper bg-dark-grey-less-opacity position-relative d-flex align-items-center w-100">
                        <input autoCorrect="off" autoFocus autoComplete="off" placeholder="Type here" ref={inputRef} type="text" id="queryInput" className={`query-input-field w-100   ${isChatStarted ? 'bg-dark-grey' : 'font-color-extra-light-grey'}`} name="query" disabled={loadingStates} value={getQueryValue} onKeyDown={handleKeyPress} onChange={handleInputChange} />
                        <button type="submit" className={`search-query-button bg-light-grey position-absolute ${(loadingStates || !nullUndefinedEmptySpaceCheck(getQueryValue)) ? 'regenerate-disable ' : 'cursorshow'}`} disabled={(loadingStates || !nullUndefinedEmptySpaceCheck(getQueryValue))} onClick={() => { setIsChatStarted(true); QueryAPITrigger(getQueryValue, 1) }}></button>
                    </div>
                    </div>

                    <h1 id='discover-descriptiondata' ref={discoverRef} style={{ maxHeight: isChatStarted ? '0px' : `${discoverHeight}px` }} className="discover-description font-color-white font-xl">Your data is ready{' '}<img src={dualStar} alt="" className="dual-start" />{' '}Discover insights that inspires you.</h1>
                </div>
                <div className="dummy-content"></div>
            </>
                : null}

            <div className="chat-screen-contents-box">
                <div className={`user-query-container chat-screen-contents ${(id) ? 'history-chat' : 'live-chat'}`}>
                    {nullUndefinedEmptyCheck(id) ? (memoizedComponent) : (socketRenderComponent)}


                    {renderQuestAnswer && renderQuestAnswer.length > 1 && renderQuestAnswer[renderQuestAnswer.length - 1].available == "true" ?
                        <div className="chat-screen-contents tell-me-more-box">
                            {!nullUndefinedEmptyCheck(id) && renderQuestAnswer && renderQuestAnswer.length > 1 &&
                                <div className="d-flex justify-content-center">
                                    <button type="button" disabled={renderQuestAnswer[renderQuestAnswer.length - 1].question_id == getPrevQuestId ? true : false} className={`tell-me-more-wrapper font-color-extra-light-grey position-relative bg-dark-grey font-exsm ${isReloadActive ? 'reload-active' : ''} ${renderQuestAnswer[renderQuestAnswer.length - 1].question_id == getPrevQuestId ? 'regenerate-disable' : ''}`} onClick={() => { checkMoreAnswer(getQueryValueForClick) }} >Tell me more about it</button>
                                </div>}

                        </div> : null
                    }
                </div>
            </div>
        </section>
    </>
}

export default ChatWindow;