import './LikeShareOptions.scss';
import like from "../../assets/ChatHistory/like.svg"
import unlike from "../../assets/ChatHistory/unlike.svg"
import share from "../../assets/ChatHistory/share.svg"
import options from "../../assets/ChatHistory/options.svg"
import { useEffect, useState } from 'react';
import Feedback from '../../Components/Feedback/Feedback';
import { AppaxiosInstance } from '../../Utilities/Interceptor/Interceptor';
import { API_END_POINTS } from '../../Constants/Constants';
import { nullUndefinedEmptyCheck } from '../../Utilities/Helpers/Helper';

const LikeShareOptions = (likeShareOptionsProps: any) => {
    const [isLikeddata, setIsLiked] = useState<any>(false);
    const [isUnLikeddata, setIsUnLiked] = useState<any>(false);
    const [isfromHistoryFlow, setisfromHistoryFlow] = useState<any>(false);
    const [hasSubmittedFeedback, setHasSubmitterFeedback] = useState<any>(false);
    useEffect(() => {
        if (nullUndefinedEmptyCheck(likeShareOptionsProps.likeUnlike)) {
            if(likeShareOptionsProps.likeUnlike){
                setIsLiked(true);
            }else{
                setIsUnLiked(true);
            }
           
        }
        setisfromHistoryFlow(likeShareOptionsProps.historyflow)
    }, [likeShareOptionsProps])

    const handleLike = () => {
        setIsLiked((prevState: any) => { return !prevState })
        unlikeComment()
    }

    const handleUnlike = () => {
        setIsUnLiked((prevState: any) => { return !prevState })
    }
    const unlikeComment = async () => {
        let payload = {
            "feedback_comment": '',
            "feedback_status": !isLikeddata
        }
        AppaxiosInstance.post(`feedback_chat?answer_id=${likeShareOptionsProps.answerId}`, payload).then((response) => {
            if (response?.data.status_code === API_END_POINTS.status_code) {
                setIsLiked(true);
            }
        })
    }
    useEffect(() => {
        if (isUnLikeddata) {
            setIsLiked(false);
        }
    }, [isUnLikeddata])

    useEffect(() => {
        if (isLikeddata) {
            setIsUnLiked(false);
            setHasSubmitterFeedback(false);
        }
    }, [isLikeddata])

    return <section className='d-flex flex-column w-100'>
{!isfromHistoryFlow ?
        <div className="like-share-options-container d-flex">
            <img src={like} alt="" className={`like-share-options bg-mild-grey-hover  ${isLikeddata ? 'bg-mild-grey' : ''}`}  onClick={handleLike} />
            <img src={unlike} alt="" className={`like-share-options bg-mild-grey-hover ${isUnLikeddata || hasSubmittedFeedback ? 'bg-mild-grey' : ""}`} onClick={handleUnlike} />
            {/* <img src={options} alt="" className="like-share-options" /> */}
        </div> :  <div className="like-share-options-container d-flex">
                <img src={like} alt="" className={`like-shareHistory-options continue-blur  ${isLikeddata ? 'bg-mild-grey' : ''}`}  />
                <img src={unlike} alt="" className={`like-shareHistory-options continue-blur ${isUnLikeddata  ? 'bg-mild-grey' : ""}`} />
            {/* <img src={options} alt="" className="like-share-options" /> */}
        </div>}

        {isUnLikeddata &&  !isfromHistoryFlow &&
            <Feedback answerId={likeShareOptionsProps.answerId} handleUnlike={handleUnlike} isUnLikeddata={isUnLikeddata} setHasSubmitterFeedback={setHasSubmitterFeedback} />
        }
    </section >
}

export default LikeShareOptions;