import AWS from "aws-sdk";

/** store data in session storage */
export const SetSessionStorageData = (sessionKey: string, payload: any) => {
  return sessionStorage.setItem(sessionKey, payload);
};

/** get data from session storage */
export const GetSessionStorageData = (sessionKey: string) => {
  return sessionStorage.getItem(sessionKey);
};
/** check for null, undefined and empty value */
export const nullUndefinedEmptyCheck = (data: any) => {
  return data !== null && data !== undefined && data !== "";
};
export const nullUndefinedEmptySpaceCheck = (inputdata: any) => {
  let data = inputdata?.trim()
  return data !== null && data !== undefined && data !== "";
};
export const checkHistoryFlow = (data: any) => {
  return (data !== null && data !== undefined && data === 'History') ? true : false
}
/** check for null value */
export const nullCheck = (data: any) => {
  return data !== null;
};

/* To clear session storage */
export const clearSessionStorage = () => {
  return sessionStorage.clear();
};

/**Upload files to S3 bucket */
export const uploadFileToS3 = (file: any) => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      region: process.env.REACT_APP_S3_REGION,
    });

    const s3 = new AWS.S3();
    const params: any = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: file.name,
      Body: file,
      ACL: "public-read",
      ContentType: file?.type,
    };

    s3.upload(params, (err: any, data: any) => {
      if (err) {
        console.error("Error uploading file:", err);
        reject(err);
      } else {
        resolve(data.Location);
      }
    });
  });
};

/**empty length check */
export const checkEmpty = (data: any): boolean => {
  return data.length !== 0;
};

// From ai tool
export const pythonStringToJSON = (uncleanedString: any) => {
  return uncleanedString
    // Convert key-value pairs from Python dictionary syntax to JSON syntax
    .replace(/(['"])?([a-zA-Z0-9_]+)(['"])?\s*:/g, '"$2":') // Ensure keys are wrapped in double quotes

    // Replace single quotes around values that are not strings
    .replace(/:\s*'([^"]*?)'\s*(?=[,}])/g, ': "$1"') // Ensure the value is properly quoted

    // Replace Python-specific literals with JSON equivalents
    .replace(/\bNone\b/g, 'null')                           // Replace None with null
    .replace(/\bTrue\b/g, 'true')                           // Replace True with true
    .replace(/\bFalse\b/g, 'false')                         // Replace False with false

    // Replace remaining single quotes with double quotes, excluding those that are part of valid strings
    .replace(/'(?![^"]*"\s*[},])/g, '"')                     // Replace single quotes not followed by a double quote and a closing bracket

    // Fix inner quotes in case of formatting issues
    .replace(/(\w)"(\w)/g, "$1'$2");                       // Fix any instances of a word followed by a quote and another word
};

export const prepareQueryLink = (link: any, params: any) => `${link}?${new URLSearchParams(params).toString()}`;
