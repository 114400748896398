import { useState } from "react";
import "./DomainFields.scss";
import mainLogo from "../../assets/DomainProgress/main-logo.svg";
import ReCAPTCHA from "react-google-recaptcha"
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom"
import { AppaxiosInstance } from '../../Utilities/Interceptor/Interceptor'
import { ROUTES, API_END_POINTS, AUTHENTICATION } from "../../Constants/Constants";
import { SetSessionStorageData, nullUndefinedEmptyCheck } from "../../Utilities/Helpers/Helper";
const DomainFields = () => {
    const navigate = useNavigate()
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false)
    const [isVerified, setisVerified] = useState(false)
    const [isShowWaitingData, setisShowWaitingData] = useState(false)
    const [errorMsg, seterrorMsg] = useState('')
    const [getdomainDetail, setdomainDetail] = useState({
        domainLink: '',
        email: ''
    })
    const submitHandler = (values: any) => {
        // setisVerified(true)
        if (isCaptchaSuccessful ) {
            let checkValid = values.domainLink.toLowerCase()
            if(!checkValid.includes('meii.ai')){
                seterrorMsg('')
            let getLinkDataPayload = {
                email: values.email,
                weblink: values.domainLink,
            }
            getLinkData(getLinkDataPayload)
        }else{
            seterrorMsg(`The website you're looking for might not be real or it might have some rules that limit access.`)
        }
        }
    }
    function onChange(value: any) {
        if (value != null) {
            setIsCaptchaSuccess(true)
        }
    }
    const getLinkData = async (payload: any) => {
        AppaxiosInstance.post('get_link', payload).then((response) => {
            if (response?.data.status_code == API_END_POINTS.status_code) {
                SetSessionStorageData(AUTHENTICATION.SHARED.ACCESS_TOKEN, response?.data.token)
                sessionStorage.setItem('weblink_id', response?.data.weblink_id);
                sessionStorage.setItem('weblink_domain', payload.weblink);
                setisShowWaitingData(true)
                //navigate(ROUTES.DATA_PROGRESS)
            } else {
                setisShowWaitingData(false)
                setisVerified(false)
                seterrorMsg(response.data.message)
            }
        })
    }

    return <>

       {!isShowWaitingData ?
            <div className="domain-input-fields-wrapper">
                <img src={mainLogo} alt="" className="main-logo" />
                <p className="free-trail-label font-exsm">Talk less, RAG more.<br />Find insights that will inspire you.<br />Enter any website and we will create a model for you to chat.</p>
                <Formik
                    initialValues={getdomainDetail}
                    enableReinitialize
                    validationSchema={Yup.object({
                        email: Yup
                            .string()
                            .required('Email is required')
                            .email('Invalid email format'),
                        domainLink: Yup
                            .string().min(5, 'Ensure this value has at least 5 characters')
                            .required('Enter domain link')
                    })}
                    onSubmit={async (values) => {
                        submitHandler(values)
                    }}
                >
                    {({
                        submitForm,
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                    }) => (<Form>
                        <div className="email-field-container d-flex flex-column ">
                            <input type="text" name='email' className="domain-field" placeholder="Enter Email Address " value={values.email}
                                onChange={(e) => {
                                    handleChange(e);
                                    setisVerified(false);
                                    seterrorMsg('')
                                }}
                                onBlur={handleBlur} />
                            {errors.email && touched.email ?
                                <span className="error-message">
                                    {errors.email}
                                </span>
                                : null}
                        </div>
                        <div className="domain-field-container d-flex flex-column " >
                            <input type="text" name='domainLink' className="domain-field" placeholder="Enter your website" onChange={(e) => {
                                handleChange(e);
                                setisVerified(false);
                                seterrorMsg('')
                            }}
                                onBlur={handleBlur} />
                            {errors.domainLink && touched.domainLink ?
                                <span className="error-message">
                                    {errors.domainLink}
                                </span>
                                : null}
                        </div>
                        <div className="recaptcha-wrapper position-relative " >
                            <div className="recaptcha-border left-border position-absolute "></div>
                            <div className="recaptcha-border right-border position-absolute "></div>
                            <div className="recaptcha-border top-border position-absolute "></div>
                            <div className="recaptcha-border bottom-border position-absolute "></div>
                            <ReCAPTCHA className="recapta-container" sitekey='6Ld5i1EpAAAAAG-xbIm90nQzMsMEWUIy7YjNnSy9' style={{ background: "transparent" }} onChange={onChange} />
                        </div>

                        {(isCaptchaSuccessful && !errors.domainLink && !errors.email) ? (
                            <button type='submit'
                                className='continue-button pointer'>Create Model</button>) : (<button disabled={true}
                                    className='continue-button continue-blur no-cursor' >Create Model</button>)
                        }
                        {!isVerified ? (<div className="error-message errorMsg">{errorMsg}</div>) : null}
                    </Form>
                    )}
                </Formik>
            </div >:

     <div className="z-3 magic-link-contents">  <h4 className=" font-md font-color-grey">Please check your inbox for our magic link</h4>
                <p className="font-exsm progress-title">We have sent you an email with our magic link in it. You can use this link to check the progress of your request. -<b>"{sessionStorage.getItem('weblink_domain')}"</b></p></div> }
    </>
}

export default DomainFields;



// import "./styles.css";
// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const data = [
//   {
//     year: "2010",
//     tr: 2400,
//     te: 1398,
//   },
//   {
//     year: "2011",
//     tr: 1398,
//     te: 1398,
//   },
//   {
//     year: "2012",
//     tr: 9800,
//     te: 3908,
//   },
//   {
//     year: "2013",
//     tr: 3908,
//     te: 4300,
//   },
//   {
//     year: "2014",
//     tr: 4800,
//     te: 3800,
//   },
//   {
//     year: "2015",
//     tr: 3800,
//     te: 4300,
//   },
//   {
//     year: "2016",
//     tr: 4300,
//     te: 3908,
//   },
// ];

// export default function App() {
//   return (
//     <ResponsiveContainer width={"100%"} height={300}>
//       <LineChart data={data}>
//         <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Line
//           type="monotone"
//           dataKey="tr"
//           stroke="#8884d8"
//           dot={{ stroke: "#8884d8", strokeWidth: 1 }}
//           activeDot={{ stroke: "#8884d8", strokeWidth: 2 }}
//           strokeWidth={3}
//           animationDuration={1600}
//           animationBegin={1}
//           animationEasing="ease-in-out"
//         />
//         <Line
//           type="monotone"
//           dataKey="te"
//           stroke="#FE9900"
//           dot={{ stroke: "#FE9900", strokeWidth: 1 }}
//           activeDot={{ stroke: "#FE9900", strokeWidth: 2 }}
//           strokeWidth={3}
//           animationDuration={1600}
//           animationBegin={1}
//           animationEasing="ease-in-out"
//         />
//       </LineChart>
//     </ResponsiveContainer>
//   );
// }
