import React from "react";
import {
  CHART_REDIRECT_LINK,
  orgnizationQuickButtons,
  REDIRECT_LINK_PARAMS_MAP,
} from "../../Constants/Constants";
import { prepareQueryLink } from "../../Utilities/Helpers/Helper";

const ChartMessage = ({ answer }:any) => {
  const { staticDesc, bucket } =
    REDIRECT_LINK_PARAMS_MAP?.[answer.chart_type] || {};

  const { icon, uiQuery } = orgnizationQuickButtons[5];

  return (
    <>
      {staticDesc && (
        <div className="d-flex user-answer-wrapper">
          <p className="font-color-extra-light-grey font-exsm whitespace meii-text-message">{staticDesc}</p>
        </div>
      )}
      {bucket && answer?.ein && (
        <div className="suggestion-chip-container">
          <a
            className="suggestion-chips redirect-link"
            href={prepareQueryLink(CHART_REDIRECT_LINK, {
              bucket: bucket,
              einnum: answer?.ein,
            })}
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-button-wrapper">
              {icon}
              {uiQuery}
            </div>
          </a>
        </div>
      )}
    </>
  );
};

export default ChartMessage;
